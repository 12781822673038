import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {newAlusalenotedetail, updateAlusalenotedetail} from "./actions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";

const ui = {
    fields: {

    }
}

export default function EditSalenotedetailDialog(props) {
    const {open, setOpen, selected} = props;
    const [state, setState] = React.useState({
        salenotedetailid: selected || {},
        fields: [],
        isComplete: false,
        title: 'Nuevo detalle',
        renderUI: ui
    });

    const handledChange = (record, complete, field, value) => {
        const {fields} = state;
        const fieldsToSend = fields.reduce((l, c) => {
            const selected = record[c.key];
            const objectRender = typeof selected === 'object' && selected !== null
            return [...l, {...c, selected: field === c.key ? (objectRender ? selected : {value: selected}) : c.selected, isTrigger: c.key === field }]
        }, []);
        setState({...state, fieldsToSend: fieldsToSend})
        refreshView(fieldsToSend);
    }

    useEffect(() =>{
        const {salenotedetailid} = state;
        if (salenotedetailid && salenotedetailid.salenotedetailid){
            setState({...state, title: 'Editar detalle'})
        }
        refreshView();
    }, [])

    const refreshView = (fields) => {
        const toSend = {fields: fields || [], salenotedetailid: {...salenotedetailid}};
        doPost('mobile-production/createOrUpdateSalenotedetail', toSend, r => {
            const uiToRender = r.fields.reduce((l, c) => {
                const isObjectRender = c.clazz.includes('es.rbm.model')
                const fieldToAdd = { [c.key] : { name: c.key, label: c.label , typeName: isObjectRender ? 'ObjectRender' : c.clazz,
                        editable: true, size: 6, records: c.options ? [...c.options] : []}}
                r.salenotedetailid[c.key] = isObjectRender ? c.selected : c.selected?.value;
                return {...l, ...fieldToAdd}
            }, {});
            const newVar = {fields: {...uiToRender, ...ui.fields}};

            setState({...state, fields: r.fields, salenotedetailid: r.salenotedetailid, isComplete: r.isComplete, renderUI: newVar})
        })
    }

    const onSuccessClick = () => {
        const {fieldsToSend} = state;
        const toSend = {fields: fieldsToSend || [], salenotedetailid: {...salenotedetailid}};
        newAlusalenotedetail(toSend, () => setOpen(!open))
    };

    const {renderUI, title, isComplete, salenotedetailid} = state;
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5, width: '100%'}}>{title}</Typography>
            <SingleRecordForm ui={renderUI} records={salenotedetailid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    )
}
