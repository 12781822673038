import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import WorkerContractView from "./WorkerContractView";
import WorkerPresenceView from "./WorkerPresenceView";
import {defaultSendMail} from "../../../../../../View/Form/utils";
import WorkerWorkernoteView from "./WorkerWorkernoteView";
import AttributeWorkerDialog from "./AttributeWorkerDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import WorkerDocumentView from "./WorkerDocumentView";
import {townUI} from "../Other/townCrud";
import {stateUI} from "../Other/stateCrud";
import {countryUI} from "../Other/countryCrud";

const getUiTab = (workerid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Trabajador", component: <EditObjectDialog objectid={workerid} entityDefinition={workerUI}/> },
                {label : "Contratos", component: <WorkerContractView workerid={workerid}/>},
                {label : "Presencia", component: <WorkerPresenceView workerid={workerid}/>},
                {label : "Notas", component: <WorkerWorkernoteView workerid={workerid}/>},
                {label : "Atributos", component: <AttributeWorkerDialog workerid={workerid}/> },
                {label : "Documentos", component: <WorkerDocumentView workerid={workerid}/> },
            ]}
    })
}

const autoComplete = (record, field, value, callback) =>{
    if (field === 'townid') {
        const newRecord = {...record};
        const townid = record?.townid;
        if (townid) {
            newRecord.stateid = townid?.stateid;
            newRecord.countryid = townid?.stateid?.countryid;
        }
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}

const groupByState = (option) => option?.stateid?.state || '';
const groupByCountry = (option) => option?.countryid?.country || '';

export const workerUI = {
    entity : {
        name: 'worker',
        keyField: 'workerid',
        label: 'Trabajador',
        urlExcelImport: 'importer/importWorkerFromExcel',
        urlDownloadTemplate: 'importer/getWorkerTemplate',
        info: {typeName: 'es.rbm.model.jpa.Worker'}
    },
    uiTable: {
        fields:{
            workerid : {name: 'workerid', label:'#' , typeName: 'Number', editable: false, flex: 0.6, sort: 'asc', optional: true },
            name : {name: 'name', label:'Nombre' , typeName: 'String', editable: true},
            surname : {name: 'surname', label:'Apellidos' , typeName: 'String', editable: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI' , typeName: 'String', editable: true, align: 'center', flex: 0.75},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', editable: true},
            birthday : {name: 'birthday', label:'Fecha de nacimiento' , typeName: 'Date', editable: true, flex: 0.8},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true, },
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, optional: true},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true, flex: 0.5},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true},
            accountid : {name: 'accountid', label:'Usuario del sistema' , typeName: 'es.rbm.model.jpa.Account', editable: true, flex: 0.5, optional: true},
        },
        keyComponent: 'workerCrudManagement',
        actionFlex: 0.8,
        actions:{
            sendEmail:{
                fun: (entity ,setOpenMail, setMailRecords) => defaultSendMail(entity,'email',setOpenMail, setMailRecords)
            },
        },
    },
    uiForm : {
        fields:{
            name : {name: 'name', label:'Nombre' , typeName: 'String', editable: true},
            surname : {name: 'surname', label:'Apellidos' , typeName: 'String', editable: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI' , typeName: 'String', editable: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', editable: true},
            birthday : {name: 'birthday', label:'Fecha de nacimiento' , typeName: 'Date', editable: true, empty: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true, addObject: townUI, groupBy: groupByState},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, addObject: stateUI, groupBy: groupByCountry},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'Number', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, addObject: countryUI},
            accountid : {name: 'accountid', label:'Usuario del sistema' , typeName: 'es.rbm.model.jpa.Account', editable: true, optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        fields: {
            name : {name: 'name', label: 'Nombre' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            surname : {name: 'surname', label: 'Apellidos' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            birthday : {name: 'birthday', label:'Fecha nacimiento' , typeName: 'Date', operator: OPERATORS.OPERATOR_EQUAL, empty: true, optional: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            systemuser : {name: 'systemuser', label:'Usuario de sistema' , typeName: 'String',  operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    uiTab: (worker) => getUiTab(worker)
}
