import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../View/Button/SuccessButton";
import {doPostRest, doPostRestList} from "../../../../../Utils/Restclient/NetworkRestActions";
import {timeticktypes} from "../../../../../Utils/Constant";
import {getKeyByValue} from "../../../../../Utils/UtilsCommon";



const defaultUI = {
    fields:{
        timeticktype : {name: 'timeticktype', label:'Tipo' , typeName: 'Enum', valueOptions: timeticktypes, size: 6},
        timetick : {name: 'timetick', label:'Fecha' , typeName: 'Datetime', size: 6},
        workerid : {name: 'workerid', label:'Trabajador' , typeName: 'es.rbm.model.jpa.Worker', size: 12, isMulti: true},
    }
}

const types = {"ENTRADA" : 1, "SALIDA" : 2}

export default function EditTimetickDialog(props) {
    const {setOpen, selected} = props;
    const [timetickid, setTimetickid] = React.useState(selected || {});
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Nuevo marcaje');
    const [ui, setUI] = React.useState(defaultUI);
    const [editMode, setEditMode] = React.useState(false);

    const handledChange = (record, complete) => {
        setTimetickid({...record});
        setComplete(complete);
    }

    useEffect(() => {
        if (timetickid && timetickid.timetickid) {
            setEditMode(true);
            const key = getKeyByValue(types, timetickid.timeticktype);
            key && setTimetickid({...timetickid, timeticktype: key});
            setTitle('Editar marcaje')
            const newUI = {
                fields: {...defaultUI.fields}
            }
            newUI.fields.workerid.isMulti = false;
            setUI(newUI);
        }
    }, [props])

    const onSuccessClick = () => {
        if (editMode) {
            const toSend = {...timetickid, timeticktype: types[timetickid.timeticktype]}
            doPostRest('timetick', toSend, () => setOpen())
        } else {
            const {timetick, workerid, timeticktype} = timetickid;
            const toSend = workerid.map(w => {
                return {
                    timetick: timetick,
                    workerid: w,
                    timeticktype: types[timeticktype]
                }
            });
            doPostRestList('timetick', toSend, () => setOpen())
        }
    };
    const onNegativeClick = (e) => {
        e.preventDefault()
        setOpen()
    };
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center',}}>
            <Typography component="h1" variant="h5" align={'center'} sx={{mb: 3}}>{title}</Typography>
            <SingleRecordForm ui={ui} records={timetickid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    )
}
