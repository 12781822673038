import * as React from 'react';
import {useEffect} from "react";
import {getGridTableUI} from "./utils";
import {
    DataGrid,
    GridToolbar,
    useGridApiRef,
    GridToolbarQuickFilter,
    GridEditModes,
} from "@mui/x-data-grid";
import {uiRowDatagridColor} from "../../Utils/uiColor";
import Grid from "@mui/material/Grid";
import {generateRandomKey} from "../../Utils/UtilsCommon";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { esES } from '@mui/x-data-grid/locales';
import Dialog from "@mui/material/Dialog";
import SelectGridTableEntity from "./SelectGridTableEntity";
import SuccessButton from "../Button/SuccessButton";
import NegativeButton from "../Button/NegativeButton";
import Typography from "@mui/material/Typography";
import WarningIcon from '@mui/icons-material/Warning';
import PropTypes from "prop-types";
import {useTheme} from "@mui/material/styles";
import {warning} from "../../Utils/Notification/notifications";

export default function GridTable(props) {
    const theme = useTheme();
    const {ui, records, onChange, rowId, toolBar, columnSorting, onSelection, selectionModel, hideFooter, sx, onRowClick,
        onCellDoubleClick, onCellKeyDown, onRowDoubleClick, density, getRowColor, getCellColor, rowHeight, filterFocus, hideDefaultRowColor, editMode} = props;
    const [uiGridTable, setUiGridTable] = React.useState({columns:[]});
    const [colorRow, setColorRow] = React.useState('default');
    const [colorCell, setColorCell] = React.useState('default');
    const [mainDensity, setMainDensity] = React.useState( localStorage[ui.keyComponent+'density'] || density || 'compact');
    const gridRef = useGridApiRef();
    const [sortModel, setSortModel] = React.useState();
    const [recordToPrint, setRecordToPrint] = React.useState([]);
    const [calculateHeight, setCalculateHeight] = React.useState(true);
    const [openSelectEntity, setOpenSelectEntity] = React.useState(false);
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
    const [fieldSelected, setFieldSelected] = React.useState(null);
    const [printOptions, setPrintOptions] = React.useState(null);
    let item = localStorage.getItem(ui.keyComponent + 'visibilityModel');
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(item ? JSON.parse(item) : {});
    let doubleControl = null;

    const handleSelectEntity = (value, uiDefinition) => {
        if (onChange) {
            setOpenSelectEntity(!openSelectEntity)
            setFieldSelected({row: value?.row, definition: {...uiDefinition}});
        }
    }

    const handleOnDelete = (row) => {
      setOpenDeleteDialog(row);
    }

    useEffect(() => {
        setColorRow(props.colorRow ? props.colorRow : 'default')
        setColorCell(props.colorCell ? props.colorCell : 'default')
        const gridTableUI = getGridTableUI({...props, printOptions: printOptions, onDelete: props.onDelete ? handleOnDelete : null, handleSelectEntity, gridRef});
        setUiGridTable(gridTableUI);
        setSortModel(defaultSortModel())
        setCalculateHeight(sx && Object.keys(sx).filter(k => k.toLowerCase().includes("height")).length > 0)
        if (!printOptions && props.onPrint?.getOptions) {
            props.onPrint.getOptions(setPrintOptions);
        }

    }, [props])

    useEffect(() => {
        if (records) {
            let toPrint = records.length === 0 ? [] : records.filter(r => r instanceof Object && Object.keys(r).length > 0);
            setRecordToPrint(toPrint);
        }
    }, [records])



    const defaultSortModel = () => {
        if (ui.keyComponent && localStorage[ui.keyComponent+'sort_model_field'] && localStorage[ui.keyComponent+'sort_model_sort']) {
             return [{ field: localStorage[ui.keyComponent+'sort_model_field'], sort: localStorage[ui.keyComponent+'sort_model_sort']}]
        } else {
            if (ui.sort) {
                return [{ field:ui.sort.field, sort: ui.sort.order }]
            } else if (ui.sort !== false) {
                const keys = Object.keys(ui.fields);
                const sort = keys.filter(k => ui.fields[k].sort);
                return sort && sort.length > 0 ? [{ field:sort, sort: ui.fields.sort }] : (keys.length? [{ field: keys[0], sort: 'desc' }] : [])
            }
        }
    }

    const handleVisibilityChanged = (model) => {
        if (ui.keyComponent){
            localStorage[ui.keyComponent + 'visibilityModel'] = JSON.stringify(model);
        }
        setColumnVisibilityModel(model)
    }

    const handleDensity = (densityValue) => {
        if (ui.keyComponent && densityValue) {
            setMainDensity(densityValue);
            localStorage[ui.keyComponent + 'density'] = densityValue;
        }
    }

    const QuickSearchToolbar = () => {
        switch (toolBar) {
            case 'S' : {
                return (
                        <Box align="right" sx={{p: 0.5, pb: 0}}>
                            <GridToolbarQuickFilter autoFocus={filterFocus} placeholder="Buscar..."/>
                        </Box>
                );
            }
            case 'D' : {
                return (
                    <Paper>
                        <Grid container sx={{p: 0.5, pb: 0}}>
                            <Grid item md={10}>
                                <GridToolbar/>
                            </Grid>
                        </Grid>
                    </Paper>
                );
            } default : {
                return (
                    <Paper>
                        <Grid container sx={{p: 0.5, pb: 0}}>
                            <Grid item md={10}>
                                <GridToolbar />
                            </Grid>
                            <Grid item sx={{mt:1}} md={2}>
                                <GridToolbarQuickFilter autoFocus={filterFocus} placeholder="Buscar..." />
                            </Grid>
                        </Grid>
                    </Paper>
                );
            }
        }
    }

    const closeSelectEntity = () => {
        setFieldSelected(null);
        setOpenSelectEntity(false)
    };

    const onSortModelChange = (model) => {
        if (ui.keyComponent){
            localStorage[ui.keyComponent+'sort_model_field'] = model.length ?  model[0].field : null;
            localStorage[ui.keyComponent+'sort_model_sort'] =  model.length ? model[0].sort : null;
        }
        setSortModel(model);
    }

    const getColorCell = (params) => {
        if (getCellColor) {
            return getCellColor(params, colorCell);
        } else {
            const color = colorCell && params.value && `color--cell--${ui.keyComponent}--${params.row[params.field][colorCell] ? params.row[params.field][colorCell] : ''}`;
            return color;
        }
    }

    function esObjeto(variable) {
        return typeof variable === 'object' && variable !== null;
    }

    const getColorRow = (params) => {
        let customColor = getCustomColor(params);
        return customColor || ((!hideDefaultRowColor && params.indexRelativeToCurrentPage % 1 !== 0) ? 'color--default' : 'color--even') || 'color--default';
    }

    const getCustomColor = (params) => {
        if (getRowColor) {
            return getColorRow(params, colorRow)
        } else {
            let value = params.row[props.colorValue]?.colorid?.value || params.row[props.colorValue]?.value;
            if (props.colorValue && value) {
                return `color--${value?.toLowerCase()}`
            } else {
                return (ui.keyComponent && params.row[colorRow] !== undefined) ? `color--${ui.keyComponent}--${esObjeto(params.row[colorRow]) ? 'object' : params.row[colorRow]}` : null;
            }
        }
    }

    const handleDialogKeyDown = (event) => {
        if (event.key === 'Escape' && openSelectEntity) {
            closeSelectEntity();
        }
    };

    const onDataGridKeyDown = (params, event) => {
        if (event.key === 'Control') {
            const {label, typeName} =  ui.fields[params.field];
            const isEntity = typeName?.startsWith('es.rbm.model');
            if (isEntity && doubleControl && !event.repeat) {
                if (ui.fields[params.field]?.editable !== false) {
                    const {ui} = props;
                    const field = ui.fields[params.field];
                    handleSelectEntity(params, field)
                } else {
                    warning('El campo ' + label + ' no es editable');
                }
            } if (!isEntity && doubleControl) {
                warning('Acción no disponible para ' + label);
            }
            doubleControl = true;
            setTimeout(() => {
                doubleControl = null;
            }, 500);
        } else if (event.key === 'Delete' && !event.ctrlKey) {
            const isEntity = ui.fields[params.field]?.typeName?.startsWith('es.rbm.model');
            if (isEntity) {
                const row = params.row;
                const newRow = {...params.row};
                newRow[params.field] = null;
                onCellEditCommit(newRow, row, true)
            }
        } else if (event.key === 'Delete' && event.ctrlKey) {
            props.onDelete && props.onDelete(params.row)
        }
        onCellKeyDown && onCellKeyDown(params, event);
    };

    const onCellEditCommit = (newRow, oldRow, refresh) => {
        let fields = props?.ui?.fields;
        let keys = Object.keys(fields);
        const fieldChange = keys.filter(k => newRow[k] !== oldRow[k])[0];
        if (fieldChange) {
            const newRowElement = newRow[fieldChange];
            if (!fields[fieldChange].optional && (newRowElement == null || ("" + newRowElement).trim().length === 0)){
                let field = fields[fieldChange];
                warning('El campo ' + field.label + ' no puede ser vacío');
                return oldRow;
            } else {
                newRow[fieldChange] = fields[fieldChange].typeName.startsWith('es.rbm.model') && newRowElement === '' ? null : newRow[fieldChange];
                onChange && onChange(newRow, oldRow, refresh);
                return newRow;
            }
        }
        return null;
    };

    return (
        <Box onKeyDown={handleDialogKeyDown} maxWidth>
            <Dialog fullWidth maxWidth={"md"} open={openSelectEntity} onClose={closeSelectEntity} onKeyDown={handleDialogKeyDown}>
                <SelectGridTableEntity onClose={closeSelectEntity} onSelected={onCellEditCommit} fieldSelected={fieldSelected} />
            </Dialog>
            <Dialog open={openDeleteDialog}  align={'center'}>
                <Box sx={{p: 2}}>
                    <Typography component="h5" variant="h5" sx={{mb: 2}}>
                        {'¿Estás seguro que deseas eliminar el dato?'}
                    </Typography>
                    <Grid container>
                        <Grid item md={2} align={'right'}>
                            <WarningIcon fontSize={'large'} color={'warning'}/>
                        </Grid>
                        <Grid item md={8}>
                            <Typography component="h7" variant="h7" sx={{my: 2}} align={'left'}>
                                {'Si borras este dato ya no podrá ser recuperado'}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container sx={{my: 2, mb: 0}}>
                        <Grid md={2}/>
                        <Grid item md={3.5} alignItems={'center'}>
                            <SuccessButton color={'error'} fullWidth sx={{mt:1}} text={'Borrar'}  onClick={() => {
                                props.onDelete(openDeleteDialog);
                                setOpenDeleteDialog(false)}} />
                        </Grid>
                        <Grid md={1}/>
                        <Grid md={3.5} alignItems={'center'}>
                            <NegativeButton fullWidth text={'Cancelar'}
                                onClick={(e) => {
                                    e.preventDefault()
                                    setOpenDeleteDialog(false);
                                }}
                                sx={{mt: 1, mb: 2}}
                            />
                        </Grid>
                        <Grid md={2}/>
                    </Grid>
                </Box>
            </Dialog>
            <DataGrid
                sx={{...sx,
                    '& .Mui-error': {
                        backgroundColor: `rgb(126,10,15, ${theme.palette.mode === 'dark' ? 0 : 0.2})`,
                        color: '#750f0f',
                    },
                    '& .MuiDataGrid-menuIconButton': {
                        color: 'white',
                    },
                    '& .MuiDataGrid-filterIcon': {
                        color: '#faa44b',
                    },
                    '& .MuiDataGrid-sortIcon': {
                        color: 'white',
                    },
                    '& .header-checkbox': {
                        backgroundColor: 'lightblue',
                        color: 'black',
                    },
                    '& .super-app-theme--header': {
                        fontWeight: 'bold',
                        bgcolor: theme.palette.primary.main,
                        color: theme.palette.info.contrastText
                    },
                    '& .MuiDataGrid-columnHeaders': {
                        backgroundColor: theme.palette.primary.main,
                    },
                    '& .MuiDataGrid-columnHeaderCheckbox': {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.palette.info.contrastText,
                    },
                    ...uiRowDatagridColor, boxShadow: 2}}
                columns={uiGridTable.columns} rows={ recordToPrint ? recordToPrint : [] }
                autoHeight={!calculateHeight} autoWidth={!calculateHeight}
                scrollbarSize={5} density={mainDensity || 'standard'}
                sortModel={columnSorting || sortModel}
                onSortModelChange={onSortModelChange}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                onDensityChange={handleDensity}
                apiRef={gridRef}
                onColumnVisibilityModelChange={handleVisibilityChanged} columnVisibilityModel={columnVisibilityModel}
                getRowId={(row) => rowId ? row[rowId] : generateRandomKey()}
                getRowClassName={getColorRow}
                slots={{
                    toolbar: toolBar && QuickSearchToolbar,
                }}
                ignoreDiacritics
                columnHeaderHeight={rowHeight || 38}
                getCellClassName={getColorCell}
                rowHeight={rowHeight || 38}
                disableDensitySelector={false}
                showCellVerticalBorder showColumnVerticalBorder
                hideFooter={hideFooter} rowSelection={false} disableRowSelectionOnClick
                onRowClick={onRowClick}
                loading={props.loading} fullWidth pagination
                onCellDoubleClick={onCellDoubleClick}
                processRowUpdate={onCellEditCommit}
                getEstimatedRowHeight={() => 38}
                onCellKeyDown={onDataGridKeyDown}
                onRowDoubleClick={onRowDoubleClick}
                editMode={editMode || GridEditModes.Cell}
                onRowSelectionModelChange={onSelection} rowSelectionModel={selectionModel} checkboxSelection={ui.checkboxSelection}
            />

        </Box>
    );
}

GridTable.propTypes = {
    ui: PropTypes.object,
    records: PropTypes.array,
    onChange: PropTypes.func,
    rowId: PropTypes.string,
    toolBar: PropTypes.string,
    columnSorting: PropTypes.array,
    onSelection: PropTypes.func,
    selectionModel: PropTypes.func,
    hideFooter: PropTypes.bool,
    sx: PropTypes.object,
    onRowClick: PropTypes.func,
    onCellDoubleClick: PropTypes.func,
    onCellKeyDown: PropTypes.func,
    onRowDoubleClick: PropTypes.func,
    getCellColor: PropTypes.func,
    getRowColor: PropTypes.func,
    handleKeyDown: PropTypes.func,
    rowHeight: PropTypes.number,
    filterFocus: PropTypes.bool,
    editMode: GridEditModes
}
