import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import uiDefinition from "../../Utils/uiDefinition";
import {doGet} from "../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../Utils/Persistence/PersistenceQuery";
import Dialog from "@mui/material/Dialog";
import SelectSelectEntity from "./SelectSelectEntity";
import {CircularProgress, Stack} from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const converterRecord = (record, props) => {
    const {keyField, descriptiveField, typeName, name} = props;
    if (record) {
        if (keyField && descriptiveField) {
            const result ={};
            result.value = record[keyField];
            result.label = record[descriptiveField];
            return result;
        } else if (uiDefinition[typeName] && uiDefinition[typeName].descriptiveField && uiDefinition[typeName].keyField) {
            const result ={};
            const uiDefinitionElement = uiDefinition[typeName];
            result.value = record[uiDefinitionElement['keyField']];
            result.label ='(' + result.value + ') ' + uiDefinitionElement['descriptiveField'].split('.').reduce((l, c) => { return l + record[c] + " "}, '');
            return result;
        } else if (uiDefinition[typeName]) {
            const result ={};
            result.value = record;
            result.label = record;
            return result;
        } else if (record && name && name.length > 0 && record[name]) {
            const result ={};
            result.value = record[name];
            result.label = record[name.replace('id', '')];
            return result;
        } else {
            const result ={};
            result.value = record;
            result.label = record;
            return result;
        }
    } else {
        return record;
    }
}

export default function SingleSelectFormv2(props) {
    const {records, defaultValue, typeName, label, onChange, urlRecords, criteriaFilter, name, filterRecords, setNewAddObject, newObjectAdded, groupBy, helperText, helperColor, addObject} = props;
    const [inputValue, setInputValue] = React.useState( '');
    const [open, setOpen] = React.useState(false);
    const [options, setOptions] = React.useState(null);
    const loading = open && options == null;
    let doubleControl = null;

    const sortOptions = (options) => {
        return groupBy ? options.sort((a, b) => {
            const groupA = groupBy(a);
            const groupB = groupBy(b);
            return groupA.localeCompare(groupB)})
            : options
    }

    const refresh = (callback) => {
        if (props) {
            if (records) {
                setOptions(sortOptions(records));
            } else if (urlRecords) {
                if ((!options?.length || newObjectAdded)) {
                    doGet(urlRecords, result => {
                        setNewAddObject(null);
                        callback && callback(result);
                    })
                }
            } else if (criteriaFilter) {
                    searchByCriteria(criteriaFilter, result => {
                        if (result) {
                            setNewAddObject(null);
                            callback && callback(result);
                        }
                    });
            } else if (filterRecords) {
                filterRecords(result => {
                    callback && callback(result);
                })
            } else {
                if ((!options?.length || openSelectEntity) || newObjectAdded) {
                    if (uiDefinition[typeName]?.url) {
                        const ui = uiDefinition[props.typeName];
                        doGet(ui.url, result => {
                            setNewAddObject(null);
                            if (result){
                                callback && callback(result);
                            }
                        })
                    }
                }
            }
        }
    }

    const handleOnChange = (e, v) => {
        const records = {name: name, value: v};
        onChange({target: {...records}})
    }

    const handleInputChange = (e, newValue) => {
        if (e && e.type === 'change') {
            e && setInputValue(newValue)
            e && inputValue !== newValue && newValue.length === 0 && handleOnChange(null, null);
        }
    }

    const [openSelectEntity, setOpenSelectEntity] = React.useState(false);
    const handleSelectEntity = () => {
        if (onChange) {
            setOpenSelectEntity(!openSelectEntity)
        }
    }

    const onKeyDown = (event) => {
        if (event.key === 'Control') {
            const isEntity = typeName?.startsWith('es.rbm.model');
            if (isEntity && doubleControl && !event.repeat) {
                const field = props?.name;
                handleSelectEntity()
            }
            doubleControl = true;
            setTimeout(() => {
                doubleControl = null;
            }, 500);
        }
    }

    const closeSelectEntity = () => {
        setOpenSelectEntity(false)
    };

    const onSelected = (v) => {
        const records = {name: name, value: v};
        onChange({target: {...records}})
    }

    React.useEffect(() => {
        let active = true;

        if (!loading) {
            return undefined;
        }

        if (active) {
            refresh(result => setOptions(sortOptions(result)));
        }

        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        if (!open) {
            setOptions(null);
        }
    }, [open]);

    React.useEffect(() => {
        const newInputValue = defaultValue ? converterRecord(defaultValue, props).label : '';
        if (inputValue !== newInputValue) {
            setInputValue(newInputValue);
        }
    }, [defaultValue]);

    return (
        <div>
            {openSelectEntity && <Dialog fullWidth maxWidth={"md"} open={openSelectEntity} onClose={closeSelectEntity} >
                <SelectSelectEntity {...props} refreshData={refresh} onSelected={onSelected} onClose={closeSelectEntity}/>
            </Dialog>}
            {
                <Box>
                    <Stack spacing={{xs: 0.1, sm: 0.1}} direction="row" justifyContent="space-between" >
                        <Autocomplete
                            id="asynchronous-dselect"
                            open={open}
                            onOpen={() => {
                                setOpen(true);
                            }}
                            fullWidth
                            onClose={() => {
                                setOpen(false);
                            }}
                            onKeyDown={onKeyDown}
                            sx={{backgroundColor: 'white'}}
                            isOptionEqualToValue={(option, newValue) => {
                                return option && newValue && option[name] === newValue[name];
                            }}
                            groupBy={groupBy}
                            getOptionLabel={(option) => option ? converterRecord(option, props).label : null}
                            options={options || []}
                            value={defaultValue}
                            inputValue={inputValue}
                            loading={loading}
                            onChange={handleOnChange}
                            onInputChange={handleInputChange}
                            size={'small'}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={label}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                        {addObject}
                    </Stack>
                    {helperText && <Typography sx={{ml: 1}} textAlign="left" color={helperColor} fontSize={12} noWrap variant="h9">
                        {helperText}
                    </Typography>}
                </Box>
            }
        </div>
    );
}
