import * as React from 'react';
import {useEffect} from "react";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../View/Button/SuccessButton";
import GridTable from "../../../../View/Form/GridTable";
import {doPost} from "../../../../Utils/Restclient/NetworkActions";
import Dialog from "@mui/material/Dialog";
import MovementLinesView from "./MovementLinesView";
import GenerateStockMovement from "./GenerateStockMovement";
import Button from "@mui/material/Button";
import MultipleSelectFormv2 from "../../../../View/Form/MultipleSelectFormv2";

const ui = {
    fields: {
        productid : {name: 'productid', label:'Producto' , typeName: 'es.rbm.model.jpa.Product', editable: false},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch', editable: false, flex: 0.5},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: false, urlRecords: 'rest/warehouse/getActives'},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', editable: false, flex: 0.5},
    },
    keyComponent: 'StockmovementManagementView'
}

export default function StockmovementManagementView(){
    const [records, setRecords] = React.useState([]);
    const initialState = {warehouseid:[], productid: [], batchFilter: true};
    const [filters, setFilters] = React.useState(initialState);
    const [openDetails, setOpenDetails] = React.useState(false);
    const [openGenerateMovement, setOpenGenerateMovement] = React.useState(false);
    const [selected, setSelected] = React.useState({});
    const [loading, setLoading] = React.useState(true);

    useEffect(() =>{
        const {warehouseid, productid} = filters;
        if(warehouseid.length> 0 || productid.length >0){
            searchRecords()
        }
    }, [openGenerateMovement, filters])

    const handledFiltersChange = (var1) =>{
        setRecords([]);
        let toSave;
        if (!Array.isArray(var1.target.value)) {
            toSave = var1.target.checked;
        } else {
            toSave = var1.target.value.map(o => o[var1.target.name]);
        }
        const newFilters = {...filters}
        newFilters[var1.target.name] = toSave;
        setFilters(newFilters);
    }

    const viewDetails = (record) => {
        const productid = record?.productid?.productid;
        const warehouseid = record?.warehouseid?.warehouseid;
        const batchid = record?.batchid?.batchid;
        if (productid && warehouseid){
            setSelected({warehouseid: warehouseid, productid: productid, batchid: batchid});
            setOpenDetails(!openDetails)
        }
    }

    const searchRecords = () => {
        setLoading(true);
        doPost("stock/getStockmovement", filters, records =>{
            setRecords(records);
            setLoading(false);
        })
    }

    const generateMovement = () => {
        setOpenGenerateMovement(!openGenerateMovement)
    }

    const onNegativeClick = () => {setOpenDetails(!openDetails) && setSelected(null)};
    return (
        <Box>
            <Dialog open={openDetails} fullWidth maxWidth="xl" onClose={onNegativeClick}>
                <Box sx={{m:1}}>
                    <Grid container>
                        <Grid item md={12}>
                            <Box overflow={"auto"}>
                                <MovementLinesView productid={selected.productid} warehouseid={selected.warehouseid} batchid={selected.batchid}/>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <Dialog open={openGenerateMovement} fullWidth maxWidth="md" onClose={() => setOpenGenerateMovement(false)}>
                <Box sx={{mx:1}}>
                    <GenerateStockMovement open={openGenerateMovement} setOpen={setOpenGenerateMovement}/>
                </Box>
            </Dialog>
            <Paper sx={{mt: 3, py: 3, px: 2}}>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                            <MultipleSelectFormv2 name={'warehouseid'} variant={'multiple'} urlRecords={'rest/warehouse/getActives'}
                                                  defaultValue={[]} typeName={'es.rbm.model.jpa.Warehouse'}
                                                  label={'Almacén'} onChange={handledFiltersChange}/>
                    </Grid>
                    <Grid item md={4}>
                            <MultipleSelectFormv2 name={'productid'} variant={'multiple'}
                                                  defaultValue={[]} typeName={'es.rbm.model.jpa.Product'}
                                                  label={'Producto'} onChange={handledFiltersChange} />
                    </Grid>
                    {/*<Grid item md={1} sx={{mt: 2}} justifyContent={'center'} alignItems={'center'}>
                        <Checkbox sx={{ml: 1}} key={'batchFilter'} name={'batchFilter'} defaultChecked={false}
                                  onChange={handledFiltersChange}  label={'Por lotes'}/>
                    </Grid>*/}
                    <Grid item md={2}/>
                    <Grid item md={2}>
                        <SuccessButton sx={{mt:0}} text={'GENERAR MOVIMIENTO'} onClick={generateMovement}/>
                    </Grid>
                </Grid>
            </Paper>
            {(filters.warehouseid.length > 0 || filters.productid.length > 0) &&
                <Paper>
                    <Box sx={{mt: 2, p:2, pt: 3}}>
                        <GridTable ui={ui}  sx={{height:'62vh'}} records={records} onEdit={viewDetails} toolBar loading={loading}/>
                    </Box>
                </Paper>}

        </Box>
    );
}
