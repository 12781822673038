import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";


const ui = {
    fields:{
        house : {name: 'house', label: 'Vivienda' ,typeName: 'String', size: 12}
    },
    keyComponent: 'NewConstructionAbsenceDialog'
}
export default function NewConstructionHouseDialog(props) {
    const {open, setOpen, construction} = props;
    const [title, setTitle] = React.useState('Nuevo tipo');
    const [houseid, setHouseid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setHouseid({...record});
        setComplete(complete);
    }

    const addHouse = (houseid, callback) => {
        const toSend = {...houseid, constructionid: {constructionid: construction.constructionid}}
        doPost('rest/house/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const houseid1 = props.houseid;
        setHouseid(houseid1 ? houseid1 : {});
        setTitle(houseid1 ? 'Editar vivienda' : 'Nueva vivienda');
    }, [props.houseid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addHouse(houseid, () => setOpen(!open))
    };

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Typography component="h1" variant="h5" align={'center'} sx={{mb: 5}}>{title}</Typography>
            <SingleRecordForm ui={ui} records={houseid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    );
}
