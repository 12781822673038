import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import HorizontalTabList from "../../Tab/HorizontalTabList";

export default function ObjectManagementDialog(props){
    const {objectid, entityDefinition} = props;

    useEffect(() => {
    }, [objectid])

    return(
        <Box sx={{m: 1, width: '100%', height: '100%', typography: 'body1'}}>
            {objectid &&
                    //<Box minHeight={'100vh'}>
                        <HorizontalTabList visible={true} showButton={false} expansible={false} selectTab={1} sideBarTab={entityDefinition.uiTab(objectid).uiTabs}/>
                    //</Box>
            }
        </Box>
    )
}
