import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useEffect} from "react";
import {deleteInventorydetail, getDetails, updateInventorydetail} from "./actions";
import Dialog from "@mui/material/Dialog";
import EditInventorydetailDialog from "./EditInventorydetailDialog";
import GridTable from "../../../../../../View/Form/GridTable";
import AddIcon from "@mui/icons-material/Add";
import DownloadIcon from '@mui/icons-material/Download';
import {getRelativeMaxViews} from "../../../../../../Utils/Constant";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";

const ui = {
    fields: {
        inventorydetailid : {name: 'inventorydetailid', label:'Codigo' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', align: 'center'},
        batchid : {name: 'batchid', label: 'Lote',typeName:'es.rbm.model.jpa.Batch' },
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
    }
}

export default function DetailsInventoryDialog(props) {
    const {selected} = props;
    const [openEditDetail, setOpenEditdetail] = React.useState(false);
    const [inventorydetails, setInventorydetails] = React.useState([]);
    const [selectedDetail, setSelectedDetail] = React.useState([]);

    useEffect(() =>{
        if (selected) {
            refresh()
        }
    }, [openEditDetail, selected])

    const refresh = () => {
        getDetails(selected, result =>{
            const records = result.map(r => {
                return {...r}
            })
            setInventorydetails(records);
        });
    }

    const onDelete = (inventorydetailid) => {
        deleteInventorydetail(inventorydetailid)
    }

    const onEdit = (inventorydetailid) => {
        setSelectedDetail(inventorydetailid)
        setOpenEditdetail(!openEditDetail);
    }
    const dumpStock = () => {
       doPost('stock/dumpStock', selected, () => refresh());
    }

    const onChange = (e) => {
        const record = inventorydetails.filter(r => r.inventorydetailid=== e.id);
        if(record && record.length>0){
            record[0][e.field] = e.value;
            updateInventorydetail(record[0])
        } else{
            updateInventorydetail(e, () => refresh())
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Dialog fullWidth maxWidth="md" open={openEditDetail} onClose={() => setOpenEditdetail(false)}>
                <EditInventorydetailDialog setOpen={setOpenEditdetail} open={openEditDetail} selected={selectedDetail}/>
            </Dialog>
            <Button size={'small'} variant="contained" onClick={() => onEdit({inventoryid: selected})} startIcon={<AddIcon />} sx={{m: 1}} disabled={!selected?.inventoryid}>
               Añadir detalle
            </Button>
            <Button size={'small'} variant="contained" onClick={dumpStock} startIcon={<DownloadIcon />} sx={{m: 1}} disabled={inventorydetails?.length}>
               Volcar stock
            </Button>
            <div>
                <GridTable ui={ui} toolBar sx={{height: getRelativeMaxViews(-3)}}
                           records={inventorydetails} rowId={'inventorydetailid'}
                           onChange={onChange} />
            </div>
        </Box>
    )
}
